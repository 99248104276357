import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { selectRedesignedFlow } from 'redux/redesignedFlow/selectors';
import { selectGender } from 'redux/onboadring/selectors';

import { Text, Image } from 'libComponents';
import TextDes from 'components/redesigned/common/TextDes';

import styles from './GoalsParametersImage.module.css';

import { GoalsImageProps } from '../types';

import { useGetParameters } from './config';
import { getGoalsTitles, getTitle } from '../config';

import defaultImage from './image.webp';

const cx = classNames.bind(styles);

const GoalsParametersImage = ({ titleConfig, femaleImage, maleImage }: GoalsImageProps) => {
    const gender = useSelector(selectGender);
    const { configPaymentParams } = useSelector(selectRedesignedFlow);

    const { t } = useTranslation();

    const title = getTitle(titleConfig);
    const parameters = useGetParameters();

    const imageFemale = femaleImage || defaultImage;
    const imageMale = maleImage || defaultImage;

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <TextDes type="h4" text={t(title)} center mb={24} />
                <Text text={t('configurablePayment.goalsBlocks.subtitle')} center mb={24} />
                <div className={styles.parameters}>
                    {parameters.map((parameter, index) => {
                        return (
                            <div key={index} className={cx('parameters__item', `parameters__item--${parameter.name}`)}>
                                <Text type="caption" text={t(parameter.title)} center />
                                <Text medium text={t(parameter.value)} center />
                            </div>
                        );
                    })}
                </div>
                <div className={styles.imageWrapper}>
                    <Image
                        src={gender === 'male' ? imageMale : imageFemale}
                        alt="image"
                        maxWidth={162}
                        className={styles.image}
                        mb={32}
                    />
                </div>
                <div className={styles.goals}>
                    {getGoalsTitles(configPaymentParams?.goalsTitles, t).map((goal, index) => (
                        <Text key={index} className={styles.goals__item} medium text={goal} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GoalsParametersImage;
